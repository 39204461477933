import { fetchJson } from '@/api/fetch';
export const getFeaturedPlaylist = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const data = await fetchJson('/track/styles/featuredPlaylist', {
        req
    });
    const styles = data.styles.sort((a, b)=>a.category.order - b.category.order).map((param)=>{
        let { category, results } = param;
        return {
            category,
            tiles: results
        };
    });
    return {
        creatorPicks: data.playlists,
        styles
    };
};
