import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { fetchWhitelistedYouTubeChannels } from '@/actions/subscriptionAction';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';
export const useWhitelistedChannels = ()=>{
    const userAuth = useAppState('userAuth');
    const { settings } = useSettings();
    return useQuery({
        queryKey: [
            'channelWhitelist',
            settings?.user.id ?? ''
        ],
        queryFn: async ()=>{
            const response = await fetchWhitelistedYouTubeChannels();
            return response.list;
        },
        placeholderData: keepPreviousData,
        staleTime: Infinity,
        enabled: !!userAuth
    });
};
