import { useQuery } from '@tanstack/react-query';
import throttle from 'lodash/throttle';
import { getAuthorizationToken } from '@/api/setup/authentication';
import { useSettings } from './useSettings';
/*
 * Only allow refresh every second, this is to prevent duplicate requesting
 * in the token expirating and the user being logged out.
 */ const throttledGetAuthorizationToken = throttle(getAuthorizationToken, 1000);
export const useAuthToken = ()=>{
    const { settings } = useSettings();
    const { isFetching } = useQuery({
        queryKey: [
            'auth',
            settings?.auth_token
        ],
        queryFn: async ()=>throttledGetAuthorizationToken(),
        refetchInterval: 240000,
        refetchIntervalInBackground: true
    });
    return {
        isFetching
    };
};
