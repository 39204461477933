const INITIAL_STATE = {
    anchor: null
};
const temporaryAnchor = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'ADD_TEMPORARY_ANCHOR':
            return Object.assign({}, state, {
                anchor: action.anchor
            });
        case 'REMOVE_TEMPORARY_ANCHOR':
            return {
                anchor: null
            };
        default:
            return state;
    }
};
export default temporaryAnchor;
