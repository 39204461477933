const videoList = [
    {
        id: 'DjwSzXcpgtY',
        name: 'Welcome to Uppbeat',
        length: '1:21',
        type: 'welcome-to-uppbeat'
    },
    {
        id: 'ZirMenr1xns',
        name: 'Welcome to Uppbeat Premium',
        length: '2:21',
        type: 'welcome-to-premium'
    },
    {
        id: '6P7CbOZ6e0Y',
        name: 'Uppbeat Free vs Premium',
        length: '1:58',
        type: 'free-vs-premium'
    },
    {
        id: 'LaeBk7K0k28',
        name: 'Uppbeat Premium vs Business',
        length: '2:08',
        type: 'premium-vs-business'
    },
    {
        id: 'WVVQOchqKLc',
        name: 'AI Playlist Generator',
        length: '4:00',
        type: 'ai-playlist-generator'
    }
];
export const getVideo = function(videoType) {
    let searchType = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 'type';
    return videoList.find((video)=>video[searchType] === videoType);
};
