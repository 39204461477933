const playlist = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        active: false
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    let playlist = {};
    let versionId = {};
    switch(action.type){
        case 'DISPLAY_PLAYLIST':
            return action.playlist;
        case 'ADD_PLAYLIST':
            if (typeof action.version === 'number') {
                versionId.id = action.version;
            } else {
                versionId = action.version;
            }
            playlist = {
                active: true,
                track: action.track,
                version: versionId
            };
            return playlist;
        case 'REMOVE_PLAYLIST':
            playlist = {
                active: false
            };
            return playlist;
        default:
            return state;
    }
};
export default playlist;
