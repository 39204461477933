import cleanDeep from 'clean-deep';
import timestring from 'timestring';
import { AUTH_TOKEN_COOKIE_NAME } from './constants/cookies';
import { getUniversalCookie, removeUniversalCookie, setUniversalCookie } from './cookies';
export const getAuthToken = (req)=>getUniversalCookie(AUTH_TOKEN_COOKIE_NAME, cleanDeep({
        req
    }));
export const setAuthToken = (value, req)=>setUniversalCookie(AUTH_TOKEN_COOKIE_NAME, value, cleanDeep({
        req,
        options: {
            maxAge: timestring('180d')
        }
    }));
export const removeAuthToken = (req)=>removeUniversalCookie(AUTH_TOKEN_COOKIE_NAME, cleanDeep({
        req
    }));
