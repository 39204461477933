import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '@/api/legacyApi';
import { fallbackSettings } from '@/api/setup/setup-frontend';
import { getAuthToken } from '@/auth';
import { CACHE_KEY, useSettings } from './useSettings';
const updateCountry = async (country)=>{
    const apiUrl = `${process.env.NEXT_PUBLIC_API}api-customer/set-country`;
    const formData = new FormData();
    if ('value' in country) {
        formData.append('country', country.value.toString());
    } else {
        formData.append('country', country.id.toString());
    }
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            token: getAuthToken() ?? ''
        },
        body: formData
    });
    const { success, message } = await response.json();
    if (!success) {
        throw new Error(message);
    }
};
export const useCountries = (type)=>{
    const queryClient = useQueryClient();
    const { invalidateSettings } = useSettings();
    const { data: countries, error, isFetching } = useQuery({
        queryKey: [
            'countries',
            type
        ],
        queryFn: async ()=>{
            const res = await api('countries');
            if (type === 'raw') {
                return res;
            }
            // Map the countries to the format needed for the select component.
            return res?.map((country)=>({
                    key: `country-${country.id}`,
                    text: country.name,
                    value: country.id,
                    vat_rate: country.vat_rate / 100,
                    iso_code_2: country.iso_code2,
                    currency: country.currency
                }));
        }
    });
    const mutation = useMutation({
        mutationFn: updateCountry,
        onMutate: async (newCountry)=>{
            // Optimistically update the cache
            await queryClient.cancelQueries({
                queryKey: [
                    CACHE_KEY
                ]
            });
            const isMappedCountry = 'value' in newCountry;
            const country = {
                id: isMappedCountry ? newCountry.value : newCountry.id,
                iso_code_2: isMappedCountry ? newCountry.iso_code_2 : newCountry.iso_code2,
                vat_rate: newCountry.vat_rate
            };
            queryClient.setQueryData(CACHE_KEY, (oldSettings)=>({
                    ...oldSettings ?? fallbackSettings,
                    country
                }));
        },
        onSettled: invalidateSettings
    });
    return {
        countries: countries ?? [],
        error,
        isFetching,
        mutation
    };
};
