const isAbsoluteUrl = (src)=>/^https?:\/\//i.test(src);
// Demo: https://static.imgix.net/daisy.png?format=auto&fit=max&w=300
/**
 * Custom loader for next/image.
 * @see https://nextjs.org/docs/app/api-reference/next-config-js/images
 */ export default function imgixLoader(param) {
    let { src, width, quality } = param;
    const url = new URL(src, 'http://temp');
    const params = url.searchParams;
    params.set('w', width.toString());
    params.set('q', quality?.toString() || '50');
    params.set('fit', 'max');
    // `auto=format` will attempt to serve images as AVIF when supported, falling back
    // to WEBP, then JPG or PNG, whereas `auto=compress` will apply various additional
    // techniques to further compress the image. The combination of the two should
    // give us the smallest image sizes.
    params.set('auto', 'format,compress');
    // If the src was not absolute to begin with return the pathname and query
    // params only.
    if (!isAbsoluteUrl(src)) {
        return `${url.pathname}${url.search ? `?${url.search}` : ''}`;
    }
    let { href } = url;
    if (href.includes('https://staging-')) {
        return href;
    }
    href = href.replace('s3.eu-west-2.amazonaws.com/', '');
    href = href.replace('cdn.musicvine.com', 'musicvine.imgix.net');
    href = href.replace('cdn.uppbeat.io', 'uppbeat.imgix.net');
    // Run Imgix URLs through Cloudfront to save bandwidth.
    // This could be simplified by just replacing the cdn.uppbeat.io check above,
    // but while we're testing this I want to leave the original code in place.
    href = href.replace('uppbeat.imgix.net', 'imgix.uppbeat.io');
    return href;
}
