/**
 * Get the status of a popup from local storage.
 *
 * Returns `true` if the popup has been dismissed.
 *
 * Returns `false` or `undefined` if the popup has not been dismissed
 */ export const getStatusFromLocalStorage = (param)=>{
    let { localStorageKey } = param;
    if (typeof localStorage !== 'undefined') {
        const status = localStorage.getItem(localStorageKey);
        if (status) {
            try {
                return JSON.parse(status);
            } catch (error) {
                console.warn('Failed to read from localStorage', error);
            }
        }
    }
    return undefined;
};
