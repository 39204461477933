const playlistFrame = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        framed: false
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'FRAMELESS_PLAYLIST':
            return Object.assign({}, state, {
                framed: false
            });
        case 'FRAMED_PLAYLIST':
            return Object.assign({}, state, {
                framed: true
            });
        default:
            return state;
    }
};
export default playlistFrame;
