const deals = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'RECEIVE_DEAL':
            state = action.deal;
        default:
            return state;
    }
};
export default deals;
