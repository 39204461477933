const headerNotifications = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        track: false,
        notification: false,
        notificationTimer: false,
        signin: false,
        playlistName: '',
        firstName: '',
        newPlaylist: false,
        animateIn: false,
        animateOut: false
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SHOW_PLAYLIST_SUCCESS':
            return Object.assign({}, state, {
                signin: false,
                track: action.track,
                playlistName: action.playlistName,
                newPlaylist: action.newPlaylist,
                animateIn: false,
                animateOut: false
            });
        case 'HIDE_PLAYLIST_SUCCESS':
            return Object.assign({}, state, {
                track: false,
                playlistName: '',
                newPlaylist: false,
                animateIn: false,
                animateOut: false
            });
        case 'ANIMATE_IN_PLAYLIST_SUCCESS':
            return Object.assign({}, state, {
                animateIn: true
            });
        case 'ANIMATE_OUT_PLAYLIST_SUCCESS':
            return Object.assign({}, state, {
                animateOut: true
            });
        case 'SHOW_SIGNIN_SUCCESS':
            return Object.assign({}, state, {
                signin: true,
                track: false,
                notification: false,
                firstName: action.firstName,
                animateIn: false,
                animateOut: false
            });
        case 'HIDE_SIGNIN_SUCCESS':
            return Object.assign({}, state, {
                signin: false,
                track: false,
                firstName: '',
                animateIn: false,
                animateOut: false
            });
        case 'SHOW_NOTIFICATION_SUCCESS':
            return Object.assign({}, state, {
                signin: false,
                track: false,
                firstName: '',
                notification: true,
                notificationTimer: action.payload || false,
                animateIn: false,
                animateOut: false
            });
        case 'HIDE_NOTIFICATION_SUCCESS':
            return Object.assign({}, state, {
                signin: false,
                track: false,
                notification: false,
                notificationTimer: false,
                firstName: '',
                animateIn: false,
                animateOut: false
            });
        case 'ANIMATE_IN_SIGNIN_SUCCESS':
            return Object.assign({}, state, {
                animateIn: true
            });
        case 'ANIMATE_OUT_SIGNIN_SUCCESS':
            return Object.assign({}, state, {
                animateOut: true
            });
        default:
            return state;
    }
};
export default headerNotifications;
