export const planData = {
    business: {
        id: 15,
        sku: {
            monthly: 'UBBZSBMN01',
            yearly: 'UBBZSBAN01'
        },
        monthly: {
            USD: '18.99',
            AUD: '6.99',
            CAD: '24.99',
            EUR: '17.99',
            GBP: '15.99'
        },
        monthly_price_in_pence: {
            USD: 1899,
            AUD: 699,
            CAD: 2499,
            EUR: 1799,
            GBP: 1599
        },
        yearly: {
            USD: '179.88',
            AUD: '66.88',
            CAD: '239.88',
            EUR: '167.88',
            GBP: '155.88'
        },
        yearly_price_in_pence: {
            USD: 17988,
            AUD: 6688,
            CAD: 23988,
            EUR: 16788,
            GBP: 15588
        },
        monthly_equivalent: {
            USD: '14.99',
            AUD: '5.99',
            CAD: '19.99',
            EUR: '13.99',
            GBP: '12.99'
        },
        monthly_equivalent_price_in_pence: {
            USD: 1499,
            AUD: 599,
            CAD: 1999,
            EUR: 1399,
            GBP: 1299
        }
    },
    premium: {
        id: 1,
        sku: {
            monthly: 'UBPRSBMN01',
            yearly: 'UBPRSBAN01'
        },
        monthly: {
            USD: '6.99',
            AUD: '6.99',
            CAD: '6.99',
            EUR: '6.99',
            GBP: '6.99'
        },
        monthly_price_in_pence: {
            USD: 699,
            AUD: 699,
            CAD: 699,
            EUR: 699,
            GBP: 699
        },
        yearly: {
            USD: '66.99',
            AUD: '66.99',
            CAD: '66.99',
            EUR: '66.99',
            GBP: '66.99'
        },
        yearly_price_in_pence: {
            USD: 6699,
            AUD: 6699,
            CAD: 6699,
            EUR: 6699,
            GBP: 6699
        },
        monthly_equivalent: {
            USD: '5.59',
            AUD: '5.59',
            CAD: '5.59',
            EUR: '5.59',
            GBP: '5.59'
        },
        monthly_equivalent_price_in_pence: {
            USD: 559,
            AUD: 559,
            CAD: 559,
            EUR: 559,
            GBP: 559
        }
    }
};
