import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';
export const getRecentDownloadsCarousels = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const token = getAuthToken(req);
    if (!token) {
        return [];
    }
    let data;
    try {
        data = await fetchJson('/carousels/10/maxslide/recent/download/artist/cache', {
            token,
            req
        });
    } catch (err) {
        console.error(err);
        return [];
    }
    if (!Array.isArray(data.data)) {
        return [];
    }
    return [
        {
            name: 'Based on your downloads',
            order: 1,
            tiles: data.data.map((item, index)=>({
                    id: index,
                    name: item.name,
                    order: index,
                    slug: '',
                    tile_image_main: item.imageAwsUrl,
                    tile_image_left: item.similarArtist1Image,
                    tile_image_right: item.similarArtist2Image,
                    main_track_artist: item.artistName,
                    main_track_name: item.trackName,
                    main_track_energy: item.trackEnergy,
                    main_track_id: item.trackId,
                    url: `/similar/${item.artistSlug}/${item.trackSlug}`
                }))
        }
    ];
};
