const collections = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'REQUEST_COLLECTIONS':
            return Object.assign({}, state, {
                isFetching: true,
                page: {
                    results: false
                }
            });
        case 'RECEIVE_COLLECTIONS':
            return Object.assign({}, state, {
                isFetching: false,
                page: action.collections
            });
        case 'ADD_COLLECTIONS':
            return action.collections;
        default:
            return state;
    }
};
export default collections;
