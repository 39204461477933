/**
 * Check if passive event listeners are supported by the current browser.
 *
 * https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
 */ export const supportsPassiveEventListeners = ()=>{
    let isSupported = false;
    try {
        const opts = Object.defineProperty({}, 'passive', {
            // eslint-disable-next-line getter-return
            get () {
                isSupported = true;
            }
        });
        const noop = ()=>{};
        window.addEventListener('testPassive', noop, opts);
        window.removeEventListener('testPassive', noop, opts);
    } catch (e) {
    // Passive events not supported
    }
    return isSupported;
};
