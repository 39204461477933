const allTrackIds = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    let newState = [];
    switch(action.type){
        case 'RECEIVE_ALL_TRACK_IDS':
            return action.trackIds;
        case 'MERGE_ALL_TRACK_IDS':
            return action.trackIds;
        case 'RECEIVE_ALL_SIMILAR_TRACK_IDS':
            newState = state.concat(action.trackIds);
            return newState;
        case 'ADD_ALL_PAGE_TRACK_IDS':
            newState.push(action.trackId);
            return newState;
        default:
            return state;
    }
};
export default allTrackIds;
