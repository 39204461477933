export function sanitizeString(str) {
    // str = str.replace(/[^a-z0-9()áéíóúñü \.,_-]/gim, '');
    return str.trim();
}
export function parseEmoji() {
    if (window.twemoji) {
        twemoji.parse(document.body, {
            folder: 'svg',
            ext: '.svg'
        });
    }
}
export function setOwnedReferralCodes(code) {
    let ownedCodes = localStorage.getItem("referral_codes");
    if (ownedCodes === null) {
        ownedCodes = [];
    } else {
        try {
            ownedCodes = JSON.parse(ownedCodes);
        } catch (e) {
            ownedCodes = [];
        }
    }
    if (!ownedCodes.includes(code) && code !== null && code !== undefined) {
        ownedCodes.push(code);
        localStorage.setItem("referral_codes", JSON.stringify(ownedCodes));
    }
}
export function getReferralCode() {
    let referralCode = localStorage.getItem('user_referral_code');
    return referralCode;
}
export function validateReferralCode() {
    let ownedCodes = localStorage.getItem("referral_codes");
    let referralCode = localStorage.getItem('user_referral_code');
    let referredBySameBrowser = "0";
    if (ownedCodes === null) {
        ownedCodes = [];
    } else {
        try {
            ownedCodes = JSON.parse(ownedCodes);
        } catch (e) {
            ownedCodes = [];
        }
    }
    if (ownedCodes.includes(referralCode)) {
        referredBySameBrowser = "1";
    }
    return referredBySameBrowser;
}
// Track length in minute:second.
export function convertToMinutesAndSeconds(time) {
    // Better handling of sound effects that are less than 0.5 seconds
    if (time <= 0.1 && time !== 0) {
        time = 1;
    }
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${`0${seconds}`.slice(-2)}`;
}
export function weightedRandom(items) {
    const total = items.reduce((sum, item)=>{
        item.weighting += sum;
        return item.weighting;
    }, 0);
    const r = Math.random() * total;
    let selectedValue = {};
    for(let i = 0; i < items.length; i++){
        if (r < items[i].weighting) {
            selectedValue = items[i];
            return items[i];
        }
    }
    return selectedValue;
}
export function getTracksIds(page, tracks) {
    const n = Number(process.env.NEXT_PUBLIC_DISPLAY_SHOW_MORE_TRACK);
    const start = page * n - n;
    const end = start + n;
    return tracks.slice(start, end);
}
;
export const modalTypes = [
    'login',
    'password',
    'signup',
    'playSignup'
];
export const disableKeyControlsRoutes = [
    '/contact',
    '/user/reset-password',
    'reset-password/confirm/:hash',
    '/my/account',
    '/artist/account',
    '/artist/downloads',
    '/artist/statement',
    '/artist/insights',
    '/checkout/cart',
    '/checkout/payment',
    '/checkout/order-pending/:orderID',
    '/checkout/order-confirmation/:orderID',
    '/pricing/subscription/checkout'
];
export const disableKeyControlsModals = [];
const getConversionContext = (pathname)=>{
    let conversionContext = "";
    let splitPathname = pathname.split('/');
    const homepageId = 1;
    const browseTrendingId = 2;
    const browseArtistId = 3;
    const browseMusicId = 4;
    const browseMusicCollectionId = 5;
    const sfxHomepageId = 6;
    const browseSfxId = 7;
    const browseSfxCollectionId = 8;
    const trackPageId = 9;
    const playlistId = 10;
    const favoritesId = 11;
    const accountAreaId = 12;
    const helpCenterId = 13;
    const similarMusicId = 14;
    const pricingPageId = 15;
    const browseMusicSearchId = 16;
    const browseSfxSearchId = 17;
    const browseMusicTagsId = 18;
    const browseSfxTagsId = 19;
    const unknownConversionId = 99;
    splitPathname.shift();
    if (splitPathname[0] === "") {
        return [
            homepageId,
            ""
        ];
    } else if (splitPathname[0] === "music") {
        conversionContext = [
            browseMusicTagsId,
            splitPathname[2]
        ];
    } else if (splitPathname[0] === "browse") {
        if (splitPathname[1] === "music") {
            conversionContext = [
                browseMusicId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "artist") {
            conversionContext = [
                browseArtistId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "trending") {
            conversionContext = [
                browseTrendingId,
                ""
            ];
        } else if (splitPathname[1] === "collection") {
            conversionContext = [
                browseMusicCollectionId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "sfx") {
            conversionContext = [
                browseSfxId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "search") {
            conversionContext = [
                browseMusicSearchId,
                splitPathname[2]
            ];
        } else {
            conversionContext = [
                browseMusicId,
                splitPathname[2]
            ];
        }
    } else if (splitPathname[0] === "sfx") {
        if (splitPathname[1] === "collection") {
            conversionContext = [
                browseSfxCollectionId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "search") {
            conversionContext = [
                browseSfxSearchId,
                splitPathname[2]
            ];
        } else if (splitPathname[1] === "tag") {
            conversionContext = [
                browseSfxTagsId,
                splitPathname[2]
            ];
        } else {
            conversionContext = [
                sfxHomepageId,
                ""
            ];
        }
    } else if (splitPathname[0] === "playlist") {
        conversionContext = [
            playlistId,
            splitPathname[1]
        ];
    } else if (splitPathname[0] === "track" || splitPathname[0] === "t") {
        conversionContext = [
            trackPageId,
            splitPathname[2]
        ];
    } else if (splitPathname[0] === "my") {
        if (splitPathname[1] === "favorites") {
            conversionContext = [
                favoritesId,
                ""
            ];
        } else {
            conversionContext = [
                accountAreaId,
                ""
            ];
        }
    } else if (splitPathname[0] === "help") {
        conversionContext = [
            helpCenterId,
            splitPathname[1]
        ];
    } else if (splitPathname[0] === "similar") {
        conversionContext = [
            similarMusicId,
            splitPathname[2]
        ];
    } else if (splitPathname[0] === "pricing") {
        conversionContext = [
            pricingPageId,
            ""
        ];
    } else {
        conversionContext = [
            unknownConversionId,
            pathname
        ];
    }
    // Safety check to make sure we're not passing empty data to the back end
    if (!conversionContext[0]) {
        conversionContext[0] = "";
    }
    if (!conversionContext[1]) {
        conversionContext[1] = "";
    }
    return conversionContext;
};
export const getEnergyColor = (energyId, trackId)=>{
    let energyColors;
    switch(energyId){
        case 1:
        case 2:
            energyColors = [
                '#1B465F',
                '#D8C4F8',
                '#D3C7C1',
                '#B3C9D2',
                '#7E92D0'
            ];
            break;
        case 3:
            energyColors = [
                '#F0A378',
                '#ACA765',
                '#5AC3CC',
                '#E6A439',
                '#F19DE6',
                "#AF55EE",
                "#59BFE5"
            ];
            break;
        case 4:
            energyColors = [
                '#7F8CD7',
                '#B7D460',
                '#EF8A33',
                '#DF4D75',
                '#F5C943',
                '#EB593A'
            ];
            break;
        case 5:
            energyColors = [
                '#111032',
                '#000001',
                '#410B38',
                '#761C1C'
            ];
            break;
        case 6:
            energyColors = [
                '#666972',
                '#514339',
                '#143649'
            ];
            break;
        default:
            energyColors = [
                '#000'
            ];
    }
    return energyColors[trackId % energyColors.length];
};
export const addConversionPoint = (eventName, conversionSource, conversionTag)=>{
    try {
        if (eventName) {
            if (eventName.includes('goPremium')) {
                localStorage.setItem('last_premium_prompt', eventName);
            }
        // window.dataLayer.push({
        //   event: eventName,
        // });
        }
        let conversionHistory = localStorage.getItem('conversion_source');
        let conversionItem = {};
        if (conversionHistory === null) {
            conversionHistory = [];
        } else {
            try {
                conversionHistory = JSON.parse(conversionHistory);
            } catch (e) {
                conversionHistory = [];
            }
        }
        let conversionContext = getConversionContext(location.pathname);
        if (conversionSource) {
            conversionItem.conversion_event = conversionSource;
            conversionItem.conversion_context_t1 = conversionContext[0];
            conversionItem.conversion_context_t2 = conversionContext[1];
            conversionItem.conversion_timestamp = Math.floor(Date.now() / 1000);
            ;
            if (conversionTag && typeof conversionTag === 'number') {
                conversionItem.conversion_tag = conversionTag;
            }
            const conversionStepsToTrack = 10;
            conversionHistory.unshift(conversionItem);
            if (conversionHistory.length > conversionStepsToTrack) {
                conversionHistory = conversionHistory.slice(0, conversionStepsToTrack);
            }
            localStorage.setItem('conversion_source', JSON.stringify(conversionHistory));
        }
    } catch (e) {
        console.error(e);
    }
};
