export default function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'LOAD_FAVORITES':
            return [
                ...action.favorites
            ];
        case 'TOGGLE_FAVORITES':
            if (state.includes(action.trackId)) {
                return state.filter((fav)=>fav !== action.trackId);
            } else {
                return [
                    ...state,
                    action.trackId
                ];
            }
        default:
            return state;
    }
};
