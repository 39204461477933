// https://developer.drip.com/?javascript#getting-started
// _dcq.push(["methodName", { key: "value", ... }]);
export function drip(event, description) {
    if (description) {
        event = `${event}: ${description}`;
    }
    try {
        _dcq.push([
            "track",
            event
        ]);
    } catch (e) {}
}
export function dripIdentify(userId, email, tag) {
    try {
        _dcq.push([
            "identify",
            {
                email: email,
                user_id: userId,
                tags: [
                    tag
                ]
            }
        ]);
    } catch (e) {}
}
export function dripProductView(product_id, name, categories, price, currency, track) {
    let productDetails = {
        product_id,
        name,
        currency
    };
    if (categories) {
        productDetails.categories = categories;
    }
    if (price) {
        productDetails.price = price;
    }
    if (track) {
        productDetails.product_url = `https://musicvine.com/track/${track.artist.slug}/${track.slug}`;
        productDetails.image_url = track.artist.avatar_image.image_aws_url;
    }
    try {
        _dcq.push([
            "track",
            "Viewed a Product",
            productDetails
        ]);
    } catch (e) {}
}
