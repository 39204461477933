/* eslint-disable */ import * as sdk from "hypertune";
export const queryId = "c542de95-cabe-4a0b-aebf-58c8824a4f8c";
export const query = {
    "variableDefinitions": {},
    "fragmentDefinitions": {},
    "fieldQuery": {
        "Query": {
            "type": "InlineFragment",
            "objectTypeName": "Query",
            "selection": {
                "root": {
                    "fieldArguments": {
                        "__isPartialObject__": true
                    },
                    "fieldQuery": {
                        "Root": {
                            "type": "InlineFragment",
                            "objectTypeName": "Root",
                            "selection": {
                                "showAudioKitGiveaway": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "audioKitGiveawayMobileLayout": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "audioKitGiveawayDismiss": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "audioKitGiveawayEnter": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "audioKitGiveawayCTA": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "showToolbar": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "testPage": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "dynamicComprehensiveListing": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "motionGraphics": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "register": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "purchase": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                },
                                "download": {
                                    "fieldArguments": {},
                                    "fieldQuery": null
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};
/**
 * @deprecated use '@vercel/flags/providers/hypertune' package instead.
 */ export const vercelFlagDefinitions = {
    "showAudioKitGiveaway": {
        "options": [
            {
                "label": "Off",
                "value": false
            },
            {
                "label": "On",
                "value": true
            }
        ],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EshowAudioKitGiveaway"
    },
    "audioKitGiveawayMobileLayout": {
        "options": [],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayMobileLayout"
    },
    "audioKitGiveawayCTA": {
        "options": [],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayCTA"
    },
    "showToolbar": {
        "options": [
            {
                "label": "Off",
                "value": false
            },
            {
                "label": "On",
                "value": true
            }
        ],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EshowToolbar"
    },
    "testPage": {
        "options": [
            {
                "label": "Off",
                "value": false
            },
            {
                "label": "On",
                "value": true
            }
        ],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EtestPage",
        "description": "Allow access to the test page"
    },
    "dynamicComprehensiveListing": {
        "options": [
            {
                "label": "Off",
                "value": false
            },
            {
                "label": "On",
                "value": true
            }
        ],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EdynamicComprehensiveListing"
    },
    "motionGraphics": {
        "options": [
            {
                "label": "Off",
                "value": false
            },
            {
                "label": "On",
                "value": true
            }
        ],
        "origin": "https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EmotionGraphics"
    }
};
export const flagFallbacks = {
    "showAudioKitGiveaway": false,
    "audioKitGiveawayMobileLayout": "",
    "audioKitGiveawayCTA": "",
    "showToolbar": false,
    "testPage": false,
    "dynamicComprehensiveListing": false,
    "motionGraphics": false
};
export function decodeFlagValues(encodedValues, flagPaths) {
    return sdk.decodeFlagValues({
        flagPaths,
        encodedValues
    });
}
export const EnvironmentEnumValues = [
    "local",
    "production",
    "preprod",
    "hades"
];
const rootFallback = {
    showAudioKitGiveaway: false,
    audioKitGiveawayMobileLayout: "",
    audioKitGiveawayDismiss: false,
    audioKitGiveawayEnter: false,
    audioKitGiveawayCTA: "",
    showToolbar: false,
    testPage: false,
    dynamicComprehensiveListing: false,
    motionGraphics: false,
    register: false,
    purchase: false,
    download: false
};
export class RootNode extends sdk.Node {
    getRootArgs() {
        const { step } = this.props;
        return step?.type === 'GetFieldStep' ? step.fieldArguments : {};
    }
    get() {
        let { fallback = rootFallback } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const getQuery = null;
        return this.getValue({
            query: getQuery,
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EshowAudioKitGiveaway})
   */ showAudioKitGiveaway(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("showAudioKitGiveaway", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "BooleanExpression") {
            const node = new sdk.BooleanNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.BooleanNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayMobileLayout})
   */ audioKitGiveawayMobileLayout(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("audioKitGiveawayMobileLayout", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "StringExpression") {
            const node = new sdk.StringNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.StringNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayDismiss})
   */ audioKitGiveawayDismiss() {
        let { args = {} } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const props0 = this.getFieldNodeProps("audioKitGiveawayDismiss", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "NoOpExpression") {
            const node = new sdk.VoidNode(props0);
            return node.get({});
        }
        const node = new sdk.VoidNode(props0);
        node._logUnexpectedTypeError();
        return node.get({});
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayEnter})
   */ audioKitGiveawayEnter() {
        let { args = {} } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const props0 = this.getFieldNodeProps("audioKitGiveawayEnter", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "NoOpExpression") {
            const node = new sdk.VoidNode(props0);
            return node.get({});
        }
        const node = new sdk.VoidNode(props0);
        node._logUnexpectedTypeError();
        return node.get({});
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EaudioKitGiveawayCTA})
   */ audioKitGiveawayCTA(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("audioKitGiveawayCTA", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "StringExpression") {
            const node = new sdk.StringNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.StringNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EshowToolbar})
   */ showToolbar(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("showToolbar", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "BooleanExpression") {
            const node = new sdk.BooleanNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.BooleanNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * Allow access to the test page
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EtestPage})
   */ testPage(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("testPage", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "BooleanExpression") {
            const node = new sdk.BooleanNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.BooleanNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EdynamicComprehensiveListing})
   */ dynamicComprehensiveListing(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("dynamicComprehensiveListing", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "BooleanExpression") {
            const node = new sdk.BooleanNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.BooleanNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3EmotionGraphics})
   */ motionGraphics(param) {
        let { args = {}, fallback } = param;
        const props0 = this.getFieldNodeProps("motionGraphics", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "BooleanExpression") {
            const node = new sdk.BooleanNode(props0);
            return node.get({
                fallback
            });
        }
        const node = new sdk.BooleanNode(props0);
        node._logUnexpectedTypeError();
        return node.get({
            fallback
        });
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3Eregister})
   */ register() {
        let { args = {} } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const props0 = this.getFieldNodeProps("register", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "NoOpExpression") {
            const node = new sdk.VoidNode(props0);
            return node.get({});
        }
        const node = new sdk.VoidNode(props0);
        node._logUnexpectedTypeError();
        return node.get({});
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3Epurchase})
   */ purchase() {
        let { args = {} } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const props0 = this.getFieldNodeProps("purchase", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "NoOpExpression") {
            const node = new sdk.VoidNode(props0);
            return node.get({});
        }
        const node = new sdk.VoidNode(props0);
        node._logUnexpectedTypeError();
        return node.get({});
    }
    /**
   * [Open in Hypertune UI]({@link https://app.hypertune.com/projects/4051/main/draft/logic?selected_field_path=root%3Edownload})
   */ download() {
        let { args = {} } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const props0 = this.getFieldNodeProps("download", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "NoOpExpression") {
            const node = new sdk.VoidNode(props0);
            return node.get({});
        }
        const node = new sdk.VoidNode(props0);
        node._logUnexpectedTypeError();
        return node.get({});
    }
    constructor(...args){
        super(...args), this.typeName = "Root";
    }
}
const sourceFallback = {
    root: {
        showAudioKitGiveaway: false,
        audioKitGiveawayMobileLayout: "",
        audioKitGiveawayDismiss: false,
        audioKitGiveawayEnter: false,
        audioKitGiveawayCTA: "",
        showToolbar: false,
        testPage: false,
        dynamicComprehensiveListing: false,
        motionGraphics: false,
        register: false,
        purchase: false,
        download: false
    }
};
/**
 * This is your project schema expressed in GraphQL.
 * 
 * Define `Boolean` fields for feature flags, custom `enum` fields for flags with 
 * more than two states, `Int` fields for numeric flags like timeouts and limits, 
 * `String` fields to manage in-app copy, `Void` fields for analytics events, and 
 * fields with custom object and list types for more complex app configuration, 
 * e.g. to use Hypertune as a CMS.
 * 
 * Once you've changed your schema, set your flag logic in the Logic view.
 */ export class SourceNode extends sdk.Node {
    get(param) {
        let { args, fallback = sourceFallback } = param;
        const getQuery = sdk.mergeFieldQueryAndArgs(query.fragmentDefinitions, sdk.getFieldQueryForPath(query.fragmentDefinitions, query.fieldQuery, []), args);
        return this.getValue({
            query: getQuery,
            fallback
        });
    }
    /**
   * You can add arguments to any field in your schema, which you can then use when
   * setting its logic, including the logic of any nested fields. Your root field 
   * already has a `context` argument. Since all flags are nested under the root 
   * field, this context will be available to all of them.
   */ root(param) {
        let { args } = param;
        const props0 = this.getFieldNodeProps("root", {
            fieldArguments: args
        });
        const expression0 = props0.expression;
        if (expression0 && expression0.type === "ObjectExpression" && expression0.objectTypeName === "Root") {
            return new RootNode(props0);
        }
        const node = new RootNode(props0);
        node._logUnexpectedTypeError();
        return node;
    }
    constructor(...args){
        super(...args), this.typeName = "Query";
    }
}
export function createSource(param) {
    let { token, variableValues = {}, override, ...options } = param;
    return sdk.create({
        NodeConstructor: SourceNode,
        token,
        query,
        queryId,
        variableValues,
        override,
        options
    });
}
export const emptySource = new SourceNode({
    context: null,
    logger: null,
    parent: null,
    step: null,
    expression: null
});
export function createSourceForServerOnly(param) {
    let { token, variableValues = {}, override, ...options } = param;
    return "object" === "undefined" ? createSource({
        token,
        variableValues,
        override,
        ...options
    }) : emptySource;
}
/**
 * @deprecated use createSource instead.
 */ export const initHypertune = createSource;
