export const defaultResponsive = {
    slidesToShow: 8,
    responsiveStyles: [
        {
            breakpoint: 1580,
            settings: {
                slidesToShow: 7
            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 687,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};
export const generateResponsiveStyles = function() {
    let styles = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultResponsive, baseSlides = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 8;
    const responsiveStyles = styles.responsiveStyles ?? defaultResponsive.responsiveStyles;
    return `
  .slider-slide {
    width: calc((100% - clamp(2.5rem, 5cqw, 5rem)) / ${baseSlides});
  }

  .slider-slide:nth-child(n + ${Math.ceil(baseSlides + 1)}) .tile-image {
    opacity: 0;
  }

  .slider-slide .tile-image.loaded {
    opacity: 1 !important;
  }

  ${responsiveStyles.map((style)=>`
        @container (max-width: ${style.breakpoint}px) {
          .slider-slide {
            width: calc((100% - clamp(2.5rem, 5cqw, 5rem)) / ${style.settings.slidesToShow});
          }

          .slider-slide:nth-child(n + ${Math.ceil(style.settings.slidesToShow + 1)}) .tile-image {
            opacity: 0;
          }
        }
      `).join('')}
    `;
};
