const INITIAL_STATE = {
    error: false,
    showLoader: false,
    showRecaptchaV2: false,
    isSignUpSuccess: false
};
const modal = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SIGNUP_ERROR':
            return Object.assign({}, state, {
                error: action.error
            });
        case 'SIGNUP_SHOW_LOADER':
            return Object.assign({}, state, {
                showLoader: action.showLoader
            });
        case 'SHOW_RECAPTCHA_V2':
            return Object.assign({}, state, {
                showRecaptchaV2: action.showRecaptchaV2
            });
        case 'SIGNUP_SUCCESS':
            return Object.assign({}, state, {
                isSignUpSuccess: action.payload
            });
        default:
            return state;
    }
};
export default modal;
