import { useQuery } from '@tanstack/react-query';
import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
export const useUpdateActiveCampaign = (param)=>{
    let { authToken, refreshInterval, refetchOnWindowFocus } = param;
    useQuery({
        queryKey: [
            'auth',
            authToken
        ],
        queryFn: ()=>{
            updateActiveCampaignContact();
            return true;
        },
        // 30 minutes.
        refetchInterval: refreshInterval * 3,
        // 10 minutes.
        staleTime: refreshInterval * 2,
        refetchOnWindowFocus,
        refetchIntervalInBackground: false
    });
};
