import { fetchJson } from '@/api/fetch';
export const getDiscoverCarousels = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    let data;
    try {
        data = await fetchJson('/page/carousels/discover', {
            req
        });
    } catch (err) {
        console.error(err);
        return [];
    }
    return data.map((carousel)=>({
            ...carousel,
            tiles: carousel.tiles.map((tile, index)=>({
                    id: tile.discover_carousel.id,
                    name: tile.discover_carousel.name,
                    order: tile.sort ?? index,
                    slug: '',
                    tile_image: tile.discover_carousel.image?.image_aws_url,
                    url: tile.discover_carousel.target_url,
                    ...tile.discover_carousel.description && {
                        description: tile.discover_carousel.description
                    },
                    ...tile.discover_carousel.short_description && {
                        short_description: tile.discover_carousel.short_description
                    }
                }))
        }));
};
