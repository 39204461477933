// Set currency action.
// -----------------------------------------------------------------------------
import orderBy from 'lodash/orderBy';
import { getAuthToken } from '../auth';
import { conditionallyGetCdnApi } from '../api/cdn';
export function fetchVatRate() {
    let countryCode = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const apiUrl = `${conditionallyGetCdnApi()}subscription/get_vat/${countryCode}`;
    return fetch(apiUrl, {
        method: 'GET'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchCountries() {
    const apiUrl = `${conditionallyGetCdnApi()}countries`;
    return fetch(apiUrl, {
        method: 'GET'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function checkVatNumber(vatNumber, countryID) {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}api-checkout/payment/validate-vat-number?vat_number=${vatNumber}&country_id=${countryID}`;
    const authToken = getAuthToken();
    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken || null
        }
    }).then((res)=>res.json()).catch((err)=>err);
}
export function recalculateBasket(countryID) {
    let isValid = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    const authToken = getAuthToken();
    const apiUrl = `${process.env.NEXT_PUBLIC_API}api-checkout/payment/recalculate-basket-totals?country_id=${countryID}&vat_number_valid=${isValid}`;
    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken || null
        }
    }).then((res)=>res.json()).catch((err)=>err);
}
export function checkDiscountCode(discountCode, planId, currencyId, periodId, authToken) {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/validateDiscountCode`;
    const formData = new FormData();
    formData.append('token', authToken);
    formData.append('discountCode', discountCode);
    formData.append('planId', planId);
    formData.append('currency', currencyId);
    formData.append('periodId', periodId);
    return fetch(apiUrl, {
        method: 'POST',
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchCheckoutBasket() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/payment`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateCheckoutBasket(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/payment`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                token: authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchBillingInformation() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/payment/billing-information`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updatePaymentOrderStatus(orderId) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}checkout/ajax-update-status-sca-order?orderId=${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateOrderStatus(orderId) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}checkout/ajax-update-sca-order?orderId=${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function checkOrderStatus(orderId) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/order-status/${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchOrderConfirmation(orderID) {
    const authToken = getAuthToken();
    return ()=>fetch(`${conditionallyGetCdnApi()}api-checkout/order-confirmation/${orderID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
function receiveDeal() {
    let deal = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const sortedDeals = orderBy(deal.deals, [
        'order'
    ], [
        'desc'
    ]);
    return {
        type: 'RECEIVE_DEAL',
        deal: sortedDeals
    };
}
function initialiseDeal() {
    let deal = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const sortedDeals = orderBy(deal.deals, [
        'order'
    ], [
        'desc'
    ]);
    return {
        type: 'SET_CHOSEN_DEAL',
        deal: sortedDeals[0]
    };
}
// No longer used as we removed deals.
export function fetchActiveDeal() {
    const apiUrl = `${conditionallyGetCdnApi()}deal/getCurrentDeal`;
    return function(dispatch) {
        return fetch(apiUrl, {
            method: 'GET'
        }).then((response)=>response.json(), (error)=>console.log('An error occurred.', error)).then((json)=>{
            dispatch(receiveDeal(json));
            dispatch(initialiseDeal(json));
        });
    };
}
export const acquisitionClick = (code, type)=>async (dispatch)=>{
        const authToken = getAuthToken();
        let formData = new FormData();
        formData.append('code', code);
        formData.append('type', type);
        formData.append('authToken', authToken);
        return fetch(`${process.env.NEXT_PUBLIC_API}acquisition/click`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: formData
        }).then((res)=>res.json());
    };
