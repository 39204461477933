import { hasBeenDismissed } from './hasBeenDismissed';
import { isLive } from './isLive';
import { shouldNotShowOnCurrentPage } from './shouldNotShowOnCurrentPage';
export const getNextInQueue = (param)=>{
    let { items, pathname } = param;
    if (!items || items.length === 0) {
        return -1;
    }
    return items.findIndex((item)=>isLive(item.duration) && !shouldNotShowOnCurrentPage({
            pathname,
            popupExcludeSlug: item.excludeSlug
        }) && !hasBeenDismissed({
            localStorageKey: item.localStorageKey,
            forceShow: item.forceShow
        }));
};
