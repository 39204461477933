import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';
export const getJumpBackInCarousels = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const token = getAuthToken(req);
    if (!token) {
        return [];
    }
    let data;
    try {
        data = await fetchJson('/carousels/10/maxslide/recent/download', {
            token,
            req
        });
    } catch (err) {
        console.error(err);
        return [];
    }
    if (!Array.isArray(data)) {
        return [];
    }
    return [
        {
            name: 'Jump back in',
            order: 1,
            tiles: data.map((item, index)=>({
                    id: index,
                    name: item.name,
                    order: index,
                    slug: '',
                    tile_image: item.image,
                    url: item.url,
                    type: item.type,
                    description: item.description,
                    ...item.displayName && item.displayPicture && {
                        playlistOwnDisplayName: item.displayName,
                        profile_picture: item.displayPicture
                    }
                }))
        }
    ];
};
