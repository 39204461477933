const searchCount = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        music: -1,
        sfx: -1
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'UPDATE_SEARCH_RESULT_COUNTS':
            return Object.assign({}, state, action.result_counts);
        case 'RESET_SEARCH_RESULT_COUNTS':
            return Object.assign({}, state, {
                music: -1,
                sfx: -1
            });
        default:
            return state;
    }
};
export default searchCount;
