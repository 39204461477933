const carousels = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'ADD_STYLES':
            return action.styles;
        default:
            return state;
    }
};
export default carousels;
