import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
const useDirectionalAnimation = ()=>{
    const { direction } = useScreenContext();
    const animationProps = {
        initial: {
            x: direction === 'forward' ? '100%' : '-100%',
            opacity: 0
        },
        animate: {
            x: 0,
            opacity: 1
        },
        exit: {
            x: direction === 'forward' ? '-100%' : '100%',
            opacity: 0
        }
    };
    return animationProps;
};
export default useDirectionalAnimation;
