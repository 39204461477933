import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
export function getContentTypes(contentTypes) {
    return {
        type: 'SET_DATA_CAPTURE_CONTENT_TYPES',
        'contentTypes': sortTypes(contentTypes)
    };
}
export function getVibeTypes(vibeTypes) {
    return {
        type: 'SET_DATA_CAPTURE_VIBE_TYPES',
        'vibeTypes': sortTypes(vibeTypes)
    };
}
function sortTypes(types) {
    let sortedTypes = orderBy(types, [
        'rank'
    ], [
        'asc'
    ]);
    const otherId = findIndex(sortedTypes, [
        'name',
        "Other"
    ]);
    sortedTypes.push(sortedTypes.splice(otherId, 1)[0]);
    return sortedTypes;
}
