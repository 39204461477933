import { getStatusFromLocalStorage } from './getStatusFromLocalStorage';
export const hasBeenDismissed = (param)=>{
    let { localStorageKey, forceShow } = param;
    // If forceShow is true, always return false (not dismissed)
    if (forceShow) {
        return false;
    }
    const status = getStatusFromLocalStorage({
        localStorageKey
    });
    /*
   * In local storage, a dismissed popup has a status of `true`.
   * and a status of `false` or `undefined` if it has not been dismissed
   */ if (status) {
        return true;
    }
    return false;
};
