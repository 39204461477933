import { captureException } from '@sentry/nextjs';
import { getFeaturedPlaylist } from '@/api/playlists/get-featured-playlist';
import { playlistTiles } from '@/fixtures/playlistTiles';
import { getDiscoverCarousels } from './get-discover-carousels';
import { getJumpBackInCarousels } from './get-jump-back-in-carousels';
import { getRecentDownloadsCarousels } from './get-recent-downloads-carousels';
const getFeaturedCarousels = async ()=>{
    let featuredCarousels;
    try {
        const featured = await getFeaturedPlaylist();
        featuredCarousels = featured?.styles;
        featuredCarousels.splice(2, 0, {
            category: {
                id: -1,
                order: 1,
                name: 'Top creator picks'
            },
            tiles: featured?.creatorPicks ?? playlistTiles
        });
    } catch (err) {
        captureException(err);
        featuredCarousels = [];
    }
    return featuredCarousels;
};
export const getHomepageCarousels = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const [featuredCarousels, discoverCarousels, basedOnRecentDlCarousels, jumpBackInCarousels] = await Promise.all([
        getFeaturedCarousels(),
        getDiscoverCarousels(),
        getRecentDownloadsCarousels({
            req
        }),
        getJumpBackInCarousels({
            req
        })
    ]);
    return {
        homepage: featuredCarousels,
        discover: discoverCarousels,
        basedOnRecentDl: basedOnRecentDlCarousels,
        jumpBackIn: jumpBackInCarousels
    };
};
