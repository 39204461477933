const initialState = true;
const sidebarCollapsed = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : initialState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SHOW_SIDEBAR':
            return false;
        case 'COLLAPSE_SIDEBAR':
            return true;
        default:
            return state;
    }
};
export default sidebarCollapsed;
