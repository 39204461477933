/*
 * A simple wrapper around the fetch API that adds the base URL to the API.
 * It also adds the Content-Type header to all requests.
 * The api function returns a promise that resolves to the JSON response of the
 * request. If the response status is not 2xx, the promise rejects with an
 * Error object that contains the error message from the response.
 */ import { isExcludedFromCdn, shouldAvoidCdn } from './cdn';
const api = async function(endpoint) {
    let { body, ...customConfig } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, { skipCdn, baseUrl, defaultContentType } = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {
        skipCdn: false,
        defaultContentType: 'application/json'
    };
    // Assume all requests are JSON unless explicitly set
    const headers = defaultContentType ? {
        'Content-Type': defaultContentType
    } : {};
    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        }
    };
    const customHeaders = customConfig.headers ?? {};
    // Automatially stringify the body if we're sending json
    if (body) {
        config.body = customHeaders?.['Content-Type'] === 'application/json' && typeof body === 'object' ? JSON.stringify(body) : body;
    }
    /*
   * Sometimes we want to skip the CDN:
   * - If the request is authenticated
   * - If the request is not a GET request
   * - If the request URL is excluded from the CDN
   * - If the avoidCdn cookie is set
   */ const useCdn = !skipCdn && !customHeaders?.token && !isExcludedFromCdn(endpoint) && !shouldAvoidCdn() && config.method === 'GET';
    // Allow overriding the base URL for the API
    const _baseUrl = baseUrl ?? (useCdn ? process.env.NEXT_PUBLIC_CDN_API : process.env.NEXT_PUBLIC_API);
    const response = await fetch(`${_baseUrl}${endpoint}`, config);
    if (response.ok) {
        return await response.json();
    }
    const errorMessage = await response.text();
    return Promise.reject(new Error(errorMessage));
};
export default api;
