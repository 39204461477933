import { getAuthToken } from '@/auth';
export const getAuthorizationToken = async function() {
    let { req } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const token = getAuthToken(req);
    if (token && token.length > 5) {
        return fetch(`${process.env.NEXT_PUBLIC_API}authentication/refresh-token`, {
            credentials: 'include',
            method: 'POST'
        });
    }
    return null;
};
