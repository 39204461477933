import { USER_STATE_COOKIE_NAME } from '@/constants/cookies';
import { getUniversalCookie } from '@/cookies';
export const isExcludedFromCdn = (endpoint)=>{
    let isEndpointExcludedFromCdn = false;
    if (endpoint.startsWith('/setup_frontend')) {
        isEndpointExcludedFromCdn = true;
    }
    return isEndpointExcludedFromCdn;
};
export const shouldAvoidCdn = (req)=>!!getUniversalCookie(USER_STATE_COOKIE_NAME, {
        req
    })?.avoidCdn;
export const conditionallyGetCdnApi = (req)=>shouldAvoidCdn(req) ? process.env.NEXT_PUBLIC_API : process.env.NEXT_PUBLIC_CDN_API;
