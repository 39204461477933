const INITIAL_STATE = {
    isActive: false,
    isFreeTrial: false,
    period: false,
    permittedContent: [],
    permittedDistribution: [],
    hasLoaded: false,
    overduePayment: null,
    hasExpiredNotice: false
};
export default function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'LOAD_SUBSCRIPTION_DATA':
            action.subscriptionData.hasLoaded = true;
            return {
                ...action.subscriptionData
            };
        case 'SET_HAS_EXPIRED_NOTICE':
            return Object.assign({}, state, {
                hasExpiredNotice: action.hasExpiredNotice
            });
        case 'LOAD_SUBSCRIPTION_PERMISSION':
            return {
                ...state,
                permittedContent: action.payload.permitted_content,
                permittedDistribution: action.payload.permittedDistribution
            };
        default:
            return state;
    }
};
