export function increaseCurrentStep(number) {
    return {
        type: 'INCREMENT',
        number
    };
}
export function decreaseCurrentStep(number) {
    return {
        type: 'DECREMENT',
        number
    };
}
export function setCurrentStep(number) {
    return {
        type: 'SET_CURRENT_STEP',
        number
    };
}
export function resetCurrentStep() {
    return {
        type: 'RESET'
    };
}
export function signUpTypeGoogle() {
    return {
        type: 'GOOGLE_SIGNUP'
    };
}
export function signUpTypeEmail() {
    return {
        type: 'EMAIL_SIGNUP'
    };
}
export function accountTypePremium() {
    return {
        type: 'PREMIUM_SIGNUP'
    };
}
export function accountTypeFree() {
    return {
        type: 'FREE_SIGNUP'
    };
}
export function accountTerm(string) {
    return {
        type: 'TERM',
        string
    };
}
export function setCurrentModal(number) {
    return {
        type: 'MODAL_NUMBER',
        number
    };
}
export function defaultCheckout() {
    return {
        type: 'DEFAULT_CHECKOUT'
    };
}
export function dealsCheckout() {
    return {
        type: 'DEALS_CHECKOUT'
    };
}
export function forgotPassword(boolean) {
    return {
        type: 'FORGOT_PASSWORD',
        boolean
    };
}
export function chooseLogin(boolean) {
    return {
        type: 'CHOOSE_LOGIN',
        boolean
    };
}
export function chooseSignUp(boolean) {
    return {
        type: 'CHOOSE_SIGNUP',
        boolean
    };
}
export function googleUpsellSignin(boolean) {
    return {
        type: 'GOOGLE_UPSELL',
        boolean
    };
}
export function pricingPagePath(string) {
    return {
        type: 'PRICING_PATH',
        string
    };
}
export function buttonToModal(string) {
    return {
        type: 'BUTTON_TO_MODAL',
        string
    };
}
export function setCancelSubscription(boolean) {
    return {
        type: 'CANCEL_SUBSCRIPTION',
        boolean
    };
}
export function setCancelSubscriptionSecond(boolean) {
    return {
        type: 'CANCEL_SUBSCRIPTION_2',
        boolean
    };
}
