import { getAuthToken } from "../auth";
export function showPlaylistSuccess(track, playlistName, newPlaylist) {
    return {
        type: 'SHOW_PLAYLIST_SUCCESS',
        track,
        playlistName,
        newPlaylist
    };
}
export function hidePlaylistSuccess() {
    return {
        type: 'HIDE_PLAYLIST_SUCCESS'
    };
}
export function framelessPlaylist() {
    return {
        type: 'FRAMELESS_PLAYLIST'
    };
}
export function framedPlaylist() {
    return {
        type: 'FRAMED_PLAYLIST'
    };
}
export function bulkAddPlaylist(playlistName, playlistTracks, playlistTrackVersions) {
    const formData = new FormData();
    const authToken = getAuthToken();
    formData.append('title', playlistName);
    formData.append('tracks', playlistTracks);
    formData.append('versions', playlistTrackVersions);
    return fetch(`${process.env.NEXT_PUBLIC_API}chatgpt/playlist`, {
        method: 'POST',
        headers: {
            'token': authToken
        },
        body: formData
    }).then((res)=>res.json());
}
