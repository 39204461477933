const ADS_TRACKING_ID = process.env.NEXT_PUBLIC_ADS_TRACKING_ID;
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const addAdEvent = (label, callback)=>{
    if ("object" === 'undefined' || !window.gtag || !label) {
        return;
    }
    window.gtag('event', 'conversion', {
        send_to: `${ADS_TRACKING_ID}/${label}`,
        event_callback: callback
    });
};
