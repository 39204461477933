const INITIAL_STATE = {
    finishAction: false
};
const loginFlow = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SET_LOGIN_FINISH_ACTION':
            return Object.assign({}, state, {
                finishAction: action.finishAction
            });
        default:
            return state;
    }
};
export default loginFlow;
