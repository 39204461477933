import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';
export const setThemePreference = async function(theme) {
    let { req } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const token = getAuthToken(req);
    if (!token) {
        return;
    }
    const themeNumber = theme === 'light' ? 0 : 1;
    await fetchJson(`set_theme_preference?pref=${themeNumber}`, {
        req,
        method: 'GET',
        tokenHeader: 'token',
        token
    });
    updateActiveCampaignContact();
};
