import { getHomepageCarousels } from '@/api/carousels/get-homepage-carousels';
import { getRandomIndex } from '@/utils/random';
const MINIMUM_CAROUSEL_LENGTH = 5;
const FALLBACK_GREETING = {
    keyName: 'message.freeMusicForCreators',
    default: 'Free music for creators'
};
export const DEFAULT_GREETINGS = [
    {
        keyName: 'message.goodTimeOfDay',
        default: '{greeting}, {name} 😊'
    }
];
export const HALLOWEEN_GREETINGS = [
    {
        keyName: 'message.keepItFreaky',
        default: '🎃 Keep it freaky, {name}'
    },
    {
        keyName: 'message.dontLookUnderTheDesk',
        default: 'Don’t look under the desk, {name} 👻'
    },
    {
        keyName: 'message.truthOrScare',
        default: 'Truth... or scare? 🤡'
    },
    {
        keyName: 'message.musicToRaiseYourSpirits',
        default: 'Music to raise your spirits 👻'
    },
    {
        keyName: 'message.itsTimeToBoogieMan',
        default: 'It’s time'
    },
    {
        keyName: 'message.itsTimeToBoogieMan',
        default: 'It’s time to boogie, man 👹'
    }
];
export const CHRISTMAS_GREETINGS = [
    {
        keyName: 'message.happyHoHoHolidays',
        default: 'Happy ho-ho-holidays, {name} 🎅'
    },
    {
        keyName: 'message.allTheJingleLadies',
        default: 'All the jingle ladies, all the jingle ladies! 🔔'
    },
    {
        keyName: 'message.letsJingleBellRock',
        default: 'Let’s jingle bell rock! 🤘'
    },
    {
        keyName: 'message.youSnowTheDrill',
        default: 'You snow the drill, {name} ☃️'
    },
    {
        keyName: 'message.christmasWithUppbeat',
        default: 'Christmas with Uppbeat… it’s lit 🎄'
    }
];
const getTimeGreeting = ()=>{
    const curHour = new Date().getHours();
    if (curHour < 12) {
        return 'Good morning';
    }
    if (curHour >= 17) {
        return 'Good evening';
    }
    return 'Good afternoon';
};
const isChristmas = ()=>{
    const now = new Date();
    return now.getMonth() === 11 && now.getDate() === 25;
};
const isHalloween = ()=>{
    const now = new Date();
    return now.getMonth() === 9 && now.getDate() === 31;
};
const isNewUser = ()=>{
    const isNewUserValue = sessionStorage.getItem('signUpTime');
    if (!isNewUserValue) {
        return false;
    }
    const regTime = parseInt(isNewUserValue, 10);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const isNew = !Number.isNaN(regTime) && regTime > yesterday.getTime();
    return isNew;
};
const getGreetings = ()=>{
    if (isChristmas()) {
        return CHRISTMAS_GREETINGS;
    }
    if (isHalloween()) {
        return HALLOWEEN_GREETINGS;
    }
    return DEFAULT_GREETINGS;
};
const getWelcomeMessage = ()=>{
    const timeGreeting = getTimeGreeting();
    const greetings = getGreetings();
    const greetingId = getRandomIndex(greetings);
    const welcomeMessage = greetings[greetingId];
    return {
        ...welcomeMessage,
        timeGreeting
    };
};
const getHeading = (settings)=>{
    if (!settings) {
        return FALLBACK_GREETING;
    }
    const newUser = isNewUser();
    if (settings?.user.is_authenticated) {
        if (newUser) {
            return {
                keyName: 'message.welcomeToUppbeat',
                default: 'Welcome to Uppbeat'
            };
        }
        return getWelcomeMessage();
    }
    return FALLBACK_GREETING;
};
const getSubheading = (settings)=>{
    if (!settings?.user.is_authenticated) {
        return 'No copyright claims. Your favorite beatmakers.';
    }
    if (settings?.isPremium) {
        return 'Music & sound effects for creators. No copyright issues.';
    }
    return 'Music for creators. No copyright issues.';
};
export const getMusicPageData = async (req)=>{
    const carousels = await getHomepageCarousels({
        req
    });
    return {
        carousels
    };
};
export const getMusicPageHeadings = (settings)=>{
    const heading = getHeading(settings);
    const subheading = getSubheading(settings);
    return {
        heading,
        subheading
    };
};
export const getMusicPageCacheKey = (settings)=>[
        'homepage',
        {
            isPremium: !!settings?.isPremium,
            authenticated: !!settings?.user.is_authenticated,
            name: settings?.user?.first_name ?? false
        }
    ];
export const isPopulatedCarousel = (carousel, minCarouselLength)=>(carousel?.tiles?.length ?? 0) >= (minCarouselLength ?? MINIMUM_CAROUSEL_LENGTH);
