const INITIAL_STATE = {
    track: false
};
const downloadIntent = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'ADD_DOWNLOAD_INTENT':
            return Object.assign({}, state, {
                track: action.track
            });
        case 'CLEAR_DOWNLOAD_INTENT':
            return {
                track: false
            };
        default:
            return state;
    }
};
export default downloadIntent;
