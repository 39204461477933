import { setUser } from '@sentry/nextjs';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useContext, useEffect, useState } from 'react';
import { loadAdminData } from '@/actions/adminAction';
import { loadUserData } from '@/actions/authenticationAction';
import { loadCountry } from '@/actions/countryAction';
import { loadCurrency } from '@/actions/currencyAction';
import { loadSubscriptionData } from '@/actions/subscriptionAction';
import { setupFrontend } from '@/api/setup/setup-frontend';
import { setAuthToken } from '@/auth';
import { setUniversalCookie } from '@/cookies';
import { UserStateContext } from '@/providers/UserStateProvider';
import { useAppDispatch } from './useAppDispatch';
export const CACHE_KEY = [
    'settings'
];
const getInitialSettingsFromLocalStorage = ()=>{
    if (typeof localStorage !== 'undefined') {
        const savedSettings = localStorage.getItem('settings');
        if (savedSettings) {
            try {
                return JSON.parse(savedSettings);
            } catch (error) {
                console.warn('Failed to parse settings from localStorage', error);
            }
        }
    }
    return undefined;
};
export const useSettings = function() {
    let { initialData } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const { setUserState } = useContext(UserStateContext);
    const [isClient, setIsClient] = useState(false);
    useEffect(()=>{
        setIsClient(true);
    }, []);
    const handleUserData = (data)=>{
        if (data?.user?.auth_token) {
            setAuthToken(data.user.auth_token);
        }
        if (data?.user?.is_authenticated) {
            setUserState('hasLoggedIn', 'true');
            if (data?.isPremium) {
                setUserState('isPremium', 'true');
            }
            if (data?.isBusiness) {
                setUserState('isBusiness', 'true');
            }
        }
        if (data?.user.email) {
            gtag('set', 'user_data', {
                email: data?.user.email
            });
        }
        if (data.user?.is_authenticated) {
            setUser({
                id: data.user.id
            });
        }
    };
    const handleLocalStorageAndCookies = (data)=>{
        if (typeof document !== 'undefined' && data) {
            try {
                const expiry = new Date();
                expiry.setFullYear(expiry.getFullYear() + 1);
                const cookieData = {
                    isAuthenticated: data?.user?.is_authenticated,
                    id: data?.user?.id?.toString(),
                    email: data?.user?.email,
                    emailVerified: data?.user?.isEmailVerified,
                    country: data?.user?.country,
                    isPremium: data?.isPremium,
                    isBusiness: data?.isBusiness,
                    currency: data?.currency.code
                };
                setUniversalCookie('userData', cookieData, {
                    options: {
                        sameSite: 'strict'
                    }
                });
                const dataToSave = {
                    ...data,
                    user: {
                        ...data.user,
                        auth_token: undefined
                    }
                };
                localStorage.setItem('settings', JSON.stringify(dataToSave));
            } catch (e) {
                console.warn('Something went wrong setting cookies/local storage.', e);
            }
        }
    };
    const handleReduxDispatch = (data)=>{
        if (!data?.user) {
            return;
        }
        const fullName = [
            data?.user?.first_name,
            data?.user?.last_name
        ].filter(Boolean).join(' ');
        dispatch(loadUserData({
            ...data.user,
            isPaymentTabDisplay: data.isPaymentTabDisplay,
            isSubscriptionTabDisplay: !isEmpty(data.subscriptionData),
            isPremium: data.isPremium,
            isBusiness: data.isBusiness,
            credits: data.credits,
            fullName,
            aiCredits: data.chatgpt
        }));
        dispatch(loadCurrency(data.currency));
        dispatch(loadCountry(data.country));
        // Add subscription data to redux store.
        dispatch(loadSubscriptionData(data.subscriptionData, data.user.is_authenticated));
        if (data.user.hasAccountAdmin && data.admin) {
            dispatch(loadAdminData(data.admin));
        }
    };
    const { data: settings } = useQuery({
        queryKey: [
            ...CACHE_KEY,
            isClient ? 'client' : 'server'
        ],
        queryFn: async ()=>{
            const _settings = await setupFrontend();
            handleUserData(_settings);
            handleLocalStorageAndCookies(_settings);
            handleReduxDispatch(_settings);
            return _settings;
        },
        refetchOnWindowFocus: true,
        initialData: isClient ? initialData : undefined,
        placeholderData: getInitialSettingsFromLocalStorage()
    });
    useEffect(()=>{
        if (settings?.user?.email) {
            gtag('set', 'user_data', {
                email: settings.user.email
            });
        }
    }, [
        settings?.user.email
    ]);
    const invalidateSettings = useCallback(()=>{
        void queryClient.invalidateQueries({
            queryKey: CACHE_KEY
        });
    }, [
        queryClient
    ]);
    return {
        settings,
        invalidateSettings
    };
};
