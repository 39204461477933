const INITIAL_STATE = {
    trackData: []
};
function mobileTrackMenu() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'ADD_DATA':
            return Object.assign({}, state, {
                trackData: action.array
            });
        default:
            return state;
    }
}
export default mobileTrackMenu;
