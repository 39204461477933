const INITIAL_STATE = {
    id: 1,
    symbol_left: '$',
    value: 1,
    code: 'USD',
    isAmbiguous: true
};
export default function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'LOAD_CURRENCY_DATA':
            action.currency.isAmbiguous = action.currency.symbol_left === '$';
            return Object.assign({}, state, action.currency);
        default:
            return state;
    }
};
