import { getAuthToken } from '../auth';
export function loadAdminData(adminData) {
    const showRelevancy = sessionStorage.getItem("showDebugRData") === "1";
    return {
        type: 'LOAD_ADMIN_DATA',
        adminData,
        showRelevancy
    };
}
export function toggleRelevancy() {
    return {
        type: 'TOGGLE_RELEVANCY'
    };
}
export function loadEditData(editData) {
    return {
        type: 'SET_EDIT_DATA',
        editData
    };
}
export function clearEditData(editData) {
    return {
        type: 'CLEAR_EDIT_DATA'
    };
}
export function setLastRequestedAssetCount(contentType, categoryType, categoryId) {
    return {
        type: 'SET_LAST_REQUESTED_ASSET_COUNT',
        lastRequested: [
            contentType,
            categoryType,
            categoryId
        ]
    };
}
export function setAdminAssetCount(assetCount) {
    return {
        type: 'SET_ADMIN_ASSET_COUNT',
        assetCount
    };
}
export function clearAdminAssetCount() {
    return {
        type: 'CLEAR_ADMIN_ASSET_COUNT'
    };
}
export function getAssetCount(contentType, categoryType, categoryId) {
    let authToken = getAuthToken();
    return function(dispatch) {
        return fetch(`${process.env.NEXT_PUBLIC_API}admin/assets?content_type=${contentType}&category_type=${categoryType}&category_id=${categoryId}&auth_token=${authToken}`, {
            method: "GET"
        }).then((response)=>response.json()).then((json)=>{
            dispatch(setLastRequestedAssetCount(contentType, categoryType, categoryId));
            dispatch(setAdminAssetCount([
                json.dataset.all,
                json.dataset.undefined,
                json.dataset.free,
                json.dataset.premium
            ]));
        });
    };
}
