const modal = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'REMOVE_MODAL':
            return false;
        case 'ADD_MODAL':
            return {
                type: action.modal,
                intent: action.intent,
                heading: action.heading
            };
        default:
            return state;
    }
};
export default modal;
