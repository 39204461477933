import { useContext, useMemo } from 'react';
import { ThemeContext } from '@/providers/ThemeProvider';
import PlaceholderDark from '@/styles/svgs/placeholder-dark.svg';
import PlaceholderLight from '@/styles/svgs/placeholder-light.svg';
export const usePlaceholderImage = ()=>{
    const { theme } = useContext(ThemeContext);
    const placeholder = useMemo(()=>{
        const darkPlaceholder = PlaceholderDark;
        const lightPlaceholder = PlaceholderLight;
        return theme === 'dark' ? darkPlaceholder : lightPlaceholder;
    }, [
        theme
    ]);
    return placeholder;
};
