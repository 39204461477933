import { useCallback } from 'react';
import { useTNamespace } from '@/providers/TNamespaceProvider';
export const useTranslate = (ns, options)=>{
    const currentNamespace = useTNamespace();
    // If ns is not provided, we try use the provider namespace.
    const internalT = useCallback((key, defaultValue, _settings)=>{
        if (!key) {
            return '';
        }
        if (!defaultValue) {
            return key;
        }
        return defaultValue;
    }, []);
    return {
        t: internalT,
        isLoading: false
    };
};
