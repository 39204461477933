/**
 * Create the store with asynchronously loaded reducers
 */ import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';
import { createStore, applyMiddleware, compose } from 'redux';
import createReducer from './reducers';
// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();
export default function configureStore() {
    let initialState = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const middlewares = [
        sagaMiddleware
    ];
    const enhancers = [
        applyMiddleware(...middlewares, thunk)
    ];
    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */ const composeEnhancers = "production" !== 'production' && "object" === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
    /* eslint-enable */ const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));
    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.asyncReducers = {}; // Async reducer registry
    // // Make reducers hot reloadable, see http://mxs.is/googmo
    // /* istanbul ignore next */
    // if (module.hot) {
    //   module.hot.accept('./reducers', () => {
    //     import('./reducers').then((reducerModule) => {
    //       const createReducers = reducerModule.default;
    //       const nextReducers = createReducers(store.asyncReducers);
    //       store.replaceReducer(nextReducers);
    //     });
    //   });
    // }
    return store;
}
export const storeWrapper = createWrapper(configureStore);
