import { getAuthToken } from '../auth';
export function updateCountry(country) {
    return async (dispatch)=>{
        dispatch({
            type: 'LOAD_COUNTRY_DATA',
            country
        });
        const apiUrl = `${process.env.NEXT_PUBLIC_API}api-customer/set-country`;
        const formData = new FormData();
        formData.append('country', country.id);
        return dispatch(()=>{
            return fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'token': getAuthToken()
                },
                body: formData
            }).then((res)=>res.json()).catch((err)=>err);
        });
    };
}
// Set country action.
// -----------------------------------------------------------------------------
export const loadCountry = (country)=>({
        type: 'LOAD_COUNTRY_DATA',
        country
    });
