import { getAuthToken } from '@/auth';
const handleActiveCampaignError = (error)=>{
    if (!process.env.NEXT_PUBLIC_API?.includes('hades')) {
        console.warn(error);
    } else {
        console.warn(error);
    }
};
export const updateActiveCampaignContact = ()=>{
    const authToken = getAuthToken();
    if (!authToken?.length ?? authToken?.length <= 5) {
        return;
    }
    void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Import/ByUserId`, {
        credentials: 'include',
        method: 'POST'
    }).catch((err)=>handleActiveCampaignError(err));
    return;
};
export const trackEvent = (type, info)=>{
    void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            requesttype: type,
            eventinfo: info
        })
    }).catch((err)=>handleActiveCampaignError(err));
};
export const autoRenewACEvent = (renewStatus)=>{
    void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            requesttype: 'autorenewstatus',
            eventinfo: `${renewStatus}`
        })
    }).catch((err)=>handleActiveCampaignError(err));
};
export const updateActiveCampaignEmail = ()=>{
    void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/UpdateEmail`, {
        credentials: 'include',
        method: 'POST'
    }).catch((err)=>handleActiveCampaignError(err));
};
export const channelWhitelisted = (channelName, channelId)=>{
    return fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            requesttype: 'channelwhitelisted',
            eventinfo: `Channel Whitelisted: ${channelName} Channel ID: ${channelId}`
        })
    }).then((res)=>res.json()).catch((err)=>handleActiveCampaignError(err));
};
