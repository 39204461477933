// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const addGAEvent = (action, options)=>{
    if ("object" === 'undefined' || !window.gtag) {
        return;
    }
    const formattedOptions = {
        event_category: options?.category,
        event_label: options?.label,
        value: options?.value
    };
    window.gtag('event', action, formattedOptions);
};
