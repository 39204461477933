import { urlJoin } from '@/utils/url-join';
import { isExcludedFromCdn, shouldAvoidCdn } from './cdn';
import { FetchError } from './error';
const isAbsolute = (endpoint)=>/^https?:\/\//i.test(endpoint);
const getFullUrl = (endpoint, useCdn)=>{
    if (isAbsolute(endpoint)) {
        return endpoint;
    }
    // The API should really control which endpoints are cached or not, but for now
    // it seems the solution is to call the uncached API for user-specific things.
    const baseUrl = useCdn ? process.env.NEXT_PUBLIC_CDN_API : process.env.NEXT_PUBLIC_API;
    if (!baseUrl) {
        throw new Error(`the API/CDN_API env vars must be set to make the call to ${endpoint}`);
    }
    return urlJoin(baseUrl, endpoint);
};
export const fetchJson = async function(endpoint) {
    let { token, tokenHeader = 'X-Auth-Token', req, skipCDN, ...fetchOptions } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    // Use the CDN for unauthenticated GET requests only, unless we have toggled
    // the setting to avoid the CDN.
    const useCdn = !skipCDN && !token && !shouldAvoidCdn(req) && !isExcludedFromCdn(endpoint) && (fetchOptions.method ?? 'GET').toUpperCase() === 'GET';
    const url = getFullUrl(endpoint, useCdn);
    const res = await fetch(url, {
        ...fetchOptions,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            ...token && {
                [tokenHeader]: token
            },
            ...fetchOptions?.headers,
            // Disable disk caching while skipping CDN.
            ...skipCDN && {
                'Cache-Control': 'no-store'
            }
        }
    });
    if (!res.ok) {
        throw new FetchError(res.status, res.statusText);
    }
    if (res.status === 204) {
        return {
            error: {
                status: 404,
                message: 'Content not found.'
            }
        };
    }
    return res.json();
};
