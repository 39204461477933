const INITIAL_STATE = {
    type: false,
    content: false
};
const jumpBackInContext = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : INITIAL_STATE, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SET_JBI_CONTEXT':
            return Object.assign({}, state, action.context);
        case 'RESET_JBI_CONTEXT':
            return {
                type: false,
                content: false
            };
        default:
            return state;
    }
};
export default jumpBackInContext;
