import { getAuthToken } from '../auth';
export function loadSubscriptionData(subscriptionData, isLoggedIn) {
    // Save relevant subscription data to session storage
    if (subscriptionData && !Array.isArray(subscriptionData) && subscriptionData.isActive) {
        sessionStorage.setItem('subscriptionData', JSON.stringify(subscriptionData));
        sessionStorage.setItem('is_premium', 'true');
    } else {
        sessionStorage.removeItem('subscriptionData');
        if (isLoggedIn) {
            sessionStorage.removeItem('is_premium');
        }
    }
    // Add a shorthand for "active or suspended", as that check is used often
    if (subscriptionData) subscriptionData.isActiveOrSuspended = subscriptionData.isActive || subscriptionData.isSuspended;
    return {
        type: 'LOAD_SUBSCRIPTION_DATA',
        subscriptionData
    };
}
export function setHasExpiredNotice(hasExpiredNotice) {
    return {
        type: 'SET_HAS_EXPIRED_NOTICE',
        hasExpiredNotice
    };
}
export function fetchSubscriptionPlans() {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/plan`;
    return fetch(apiUrl, {
        method: 'GET'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchSubscriptionAddons() {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/plan/addon`;
    return fetch(apiUrl, {
        method: 'GET'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchSubscriptionPlansAndAddons() {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/plan/plan_addon`;
    return fetch(apiUrl, {
        method: 'GET'
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchSubscriptionPermissions() {
    let type = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1;
    return (dispatch)=>new Promise((resolve, reject)=>{
            const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/get_permissions/plan_type_category/${type}`;
            fetch(apiUrl, {
                method: 'GET'
            }).then((res)=>res.json()).then((response)=>{
                dispatch({
                    type: 'LOAD_SUBSCRIPTION_PERMISSION',
                    payload: response
                });
                resolve(response);
            }).catch((err)=>reject(err));
        });
}
export function dismissExpirationNotice() {
    const authToken = getAuthToken();
    const apiUrl = `${process.env.NEXT_PUBLIC_API}subscription/expiredNotificationDismissAction`;
    let formData = new FormData();
    formData.append('token', authToken);
    return fetch(apiUrl, {
        method: 'POST',
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
export function setYoutubeChannel(payload) {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/update-social-channel`;
    let formData = new FormData();
    formData.append('token', getAuthToken());
    formData.append('channel', payload.channel ? payload.channel : "");
    formData.append('channelName', payload.channelName ? payload.channelName : "");
    formData.append('channelLocation', 1);
    formData.append('whitelist', true);
    return fetch(apiUrl, {
        method: 'POST',
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
export function whitelistYouTubeChannel(channelId, channelName) {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_add`;
    const formData = new FormData();
    formData.append('channel_id', channelId);
    formData.append('channel_name', channelName);
    return fetch(apiUrl, {
        method: 'POST',
        headers: {
            'token': getAuthToken()
        },
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
export function removeWhitelistedYouTubeChannel(id) {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_delete`;
    const formData = new FormData();
    formData.append('id', id);
    return fetch(apiUrl, {
        method: 'POST',
        headers: {
            'token': getAuthToken()
        },
        body: formData
    }).then((res)=>res.json()).catch((err)=>err);
}
export function fetchWhitelistedYouTubeChannels() {
    const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_list`;
    return fetch(apiUrl, {
        method: 'GET',
        headers: {
            'token': getAuthToken()
        }
    }).then((res)=>res.json()).catch((err)=>err);
}
