const wavesReducer = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'REGISTER_WAVE':
            // If the trackID already exists, append the new wavesurfer instance to the array.
            return {
                ...state,
                [action.trackID]: [
                    ...state[action.trackID] ?? [],
                    {
                        id: action.waveformId,
                        waveform: action.wavesurfer
                    }
                ]
            };
        case 'UNREGISTER_WAVE':
            // If track list contains more than one wavesurfer instance, remove the wavesurfer instance from the array.
            if (state[action.trackID]?.length > 1) {
                return {
                    ...state,
                    [action.trackID]: state[action.trackID].filter((wavesurfer)=>action.waveformId !== wavesurfer.waveformId)
                };
            }
            // If track list contains only one wavesurfer instance, delete the trackID key.
            delete state[action.trackID];
            return state;
        default:
            return state;
    }
};
export default wavesReducer;
