export default function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'LOAD_FOLLOWING_DATA':
            return [
                ...action.following
            ];
        case 'FOLLOW_ARTIST':
            return [
                ...state,
                action.ID
            ];
        case 'UNFOLLOW_ARTIST':
            return state.filter((follow)=>follow !== action.ID);
        default:
            return state;
    }
};
