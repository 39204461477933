import { getAuthToken } from '../auth';
export function addToCart(subscriptionData) {
// TODO: I don't think this is actually used, but it's being imported somewhere
}
export function addSubscriptionToCart(plan) {
    return {
        type: 'ADD_SUBSCRIPTION_TO_CART',
        plan
    };
}
export function emptySubscriptionCart() {
    sessionStorage.removeItem('basket_subscription_id');
    return {
        type: 'EMPTY_SUBSCRIPTION_CART'
    };
}
export function setChosenDeal(deal) {
    return {
        type: 'SET_CHOSEN_DEAL',
        deal
    };
}
export function setHasValidVatNumber(hasValidVatNumber) {
    return {
        type: 'SET_CART_HAS_VALID_VAT_NUMBER',
        hasValidVatNumber
    };
}
export function setSubscriptionDiscount(discount) {
    return {
        type: 'SET_SUBSCRIPTION_DISCOUNT',
        discount
    };
}
export function fetchBasket() {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/basket`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function checkDiscountCode(discountCode) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}checkout/basket/ajax-check-discount-code?discount_code=${discountCode}&auto_apply_code=0`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function addArtistTip(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-artist/tip`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateQuantity(trackID, quantity) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/basket/ajax-update-basket-track-quantity`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                basket_track_id: trackID,
                quantity
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateBasket(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/update-basket`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function updateBasketTrackLicenses(data) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/basket/update-basket-track-licenses`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken,
                ...data
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export function removeBasketTrack(trackID) {
    const authToken = getAuthToken();
    return ()=>fetch(`${process.env.NEXT_PUBLIC_API}api-checkout/basket/remove-basket-track/${trackID}`, {
            method: 'POST',
            body: JSON.stringify({
                token: authToken
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': authToken || null
            }
        }).then((res)=>res.json()).catch((err)=>err);
}
export const getAnnualDiscountPercentage = (planId)=>{
    let discountPercentage;
    switch(planId){
        case 8:
            discountPercentage = "60";
            break;
        case 9:
            discountPercentage = "40";
            break;
        case 10:
            discountPercentage = "35";
            break;
        case 11:
            discountPercentage = "45";
            break;
        case 12:
            discountPercentage = "25";
            break;
        case 13:
            discountPercentage = "15";
            break;
        case 14:
            discountPercentage = "50";
            break;
        default:
            discountPercentage = "20";
    }
    return discountPercentage + "%";
};
