const country = function() {
    let state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        id: -1,
        iso_code_2: '',
        vat_rate: 0
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'LOAD_COUNTRY_DATA':
            return action.country;
        default:
            return state;
    }
};
export default country;
