import { useMemo } from 'react';
import { useAppState } from './useAppState';
import { useSettings } from './useSettings';
const useRemainingCredits = ()=>{
    const { settings } = useSettings();
    const userAuth = useAppState('userAuth');
    const remainingCredits = userAuth?.creditsCurrent !== undefined && userAuth?.creditsCurrent >= 0 ? userAuth?.creditsCurrent : settings?.credits.credits_current;
    const renewal = settings?.user.renewed_download_date;
    const daysUntilRenewal = useMemo(()=>{
        if (renewal != null) {
            const renewalDate = Date.parse(renewal);
            const todaysDate = Date.now();
            return Math.floor((renewalDate - todaysDate) / 1000 / 60 / 60 / 24) + 1;
        }
        return null;
    }, [
        renewal
    ]);
    return {
        remainingCredits,
        daysUntilRenewal,
        renewal
    };
};
export default useRemainingCredits;
