import { useMedia } from "__barrel_optimize__?names=useMedia!=!react-use";
const DEFAULT_SCREENS = {
    xxs: '384px',
    // => @media (min-width: 384px) { ... }
    xs: '576px',
    // => @media (min-width: 576px) { ... }
    sm: '640px',
    // => @media (min-width: 640px) { ... }
    md: '768px',
    // => @media (min-width: 768px) { ... }
    lg: '1024px',
    // => @media (min-width: 1024px) { ... }
    xl: '1280px',
    // => @media (min-width: 1280px) { ... }
    '2xl': '1536px'
};
const useTWScreens = function() {
    let customScreens = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const screens = {
        ...DEFAULT_SCREENS,
        ...customScreens
    };
    const isXs = useMedia(`(min-width: ${screens.xs})`, true);
    const isSm = useMedia(`(min-width: ${screens.sm})`, true);
    const isMd = useMedia(`(min-width: ${screens.md})`, true);
    const isLg = useMedia(`(min-width: ${screens.lg})`, true);
    const isXl = useMedia(`(min-width: ${screens.xl})`, true);
    const is2Xl = useMedia(`(min-width: ${screens['2xl']})`, true);
    return {
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        is2Xl
    };
};
export default useTWScreens;
